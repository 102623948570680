<template>
  <div class="page-wrapper">
    <el-row :gutter="10">
      <el-col :sm="24" :md="24" v-for="section in sections" :key="section.id">
        <!-- 轮播图 -->
        <el-card
          class="box-card"
          shadow="never"
          v-if="section.title == 'banner'"
        >
          <div slot="header" class="clearfix">
            <span>轮播图设置 [拖动排序] </span>
            <div style="float: right; padding: 3px 0">
              <el-button size="small" @click="onRefresh">刷新</el-button>
              <el-button type="primary" size="small" @click="onSave"
                >保存</el-button
              >
            </div>
          </div>
          <DraggbleSort
            syncSortKey
            v-model="section.images"
            class="banner-list-wrap"
          >
            <template v-slot:default="slotProps">
              <ProductVideoItem
                v-if="slotProps.item.description == 'VIDEO'"
                v-model="slotProps.item.url"
                class="banner-item"
                @remove="removeBanner(section, slotProps.index)"
              />
              <!-- https://cdn.viewgres.com/ecde5a896702271c0e2a531c27bb90d7 -->
              <ProductImgUpload
                v-else
                class="banner-item"
                tips="选择图片"
                v-model="slotProps.item.url"
                @remove="removeBanner(section, slotProps.index)"
              />
            </template>
          </DraggbleSort>
          <div class="add-madias-item">
            <el-button-group>
              <el-button
                type="primary"
                size="small"
                icon="el-icon-picture-outline"
                @click="addBanner(section, 'IMAGE')"
                >添加图片</el-button
              >
              <el-button
                type="primary"
                size="small"
                icon="el-icon-picture-outline"
                @click="addBanner(section, 'VIDEO')"
                >添加视频</el-button
              >
            </el-button-group>
          </div>
        </el-card>
        <!-- 关于我们 -->
        <el-card
          class="box-card"
          shadow="never"
          v-if="section.title == 'aboutus'"
        >
          <div slot="header" class="clearfix">
            <span>首页-关于我们 </span>
            <div style="float: right; padding: 3px 0">
              <el-button size="small" @click="onRefresh">刷新</el-button>
              <el-button type="primary" size="small" @click="onSave"
                >保存</el-button
              >
            </div>
          </div>
          <el-form
            ref="form"
            :model="section"
            label-width="120px"
            label-position="top"
          >
            <el-row :gutter="10">
              <el-col :sm="24" :md="10">
                <el-form-item label="内容">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 12, maxRows: 60 }"
                    placeholder="请输入内容"
                    v-model="section.content"
                  >
                  </el-input> </el-form-item
              ></el-col>
              <el-col :sm="24" :md="14">
                <el-form-item label="封面图">
                  <SingleImgUpload
                    class="about-us-image"
                    v-model="section.imageUrl"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-card>
        <!-- 提供的服务 -->
        <el-card
          class="box-card"
          shadow="never"
          v-if="section.title == 'services'"
        >
          <div slot="header" class="clearfix">
            <span>提供的服务设置 </span>
            <div style="float: right; padding: 3px 0">
              <el-button size="small" @click="onRefresh">刷新</el-button>
              <el-button type="primary" size="small" @click="onSave"
                >保存</el-button
              >
            </div>
          </div>
          <el-form
            ref="form"
            :model="section"
            label-width="120px"
            label-position="top"
          >
            <el-row :gutter="10">
              <el-col
                :sm="24"
                :md="6"
                v-for="(seciveItem, index) in section.images"
                :key="seciveItem.id"
              >
                <el-card class="box-card">
                  <el-form-item :label="`图片 - ${index}`">
                    <SingleImgUpload
                      class="services-image"
                      v-model="seciveItem.url"
                    />
                  </el-form-item>
                  <el-form-item :label="`标题 - ${index}`">
                    <el-input
                      type="input"
                      placeholder="请输入"
                      v-model="seciveItem.title"
                    ></el-input>
                  </el-form-item>
                  <el-form-item :label="`描述 - ${index}`">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 8, maxRows: 8 }"
                      placeholder="请输入描述"
                      v-model="seciveItem.description"
                    >
                    </el-input>
                  </el-form-item>
                </el-card>
              </el-col>
            </el-row>
          </el-form>
        </el-card>
        <!-- 工厂图 -->
        <el-card
          class="box-card"
          shadow="never"
          v-if="section.title == 'FACTORY'"
        >
          <div slot="header" class="clearfix">
            <span>工厂图 [拖动排序] </span>
            <div style="float: right; padding: 3px 0">
              <el-button size="small" @click="onRefresh">刷新</el-button>
              <el-button type="primary" size="small" @click="onSave"
                >保存</el-button
              >
            </div>
          </div>
          <DraggbleSort
            syncSortKey
            v-model="section.images"
            class="banner-list-wrap"
          >
            <template v-slot:default="slotProps">
              <ProductImgUpload
                class="banner-item"
                tips="选择图片"
                v-model="slotProps.item.url"
                @remove="removeBanner(section, slotProps.index)"
              />
            </template>
          </DraggbleSort>
          <div class="add-madias-item">
            <el-button-group>
              <el-button
                type="primary"
                size="small"
                icon="el-icon-picture-outline"
                @click="addBanner(section)"
                >添加图片</el-button
              >
            </el-button-group>
          </div>
        </el-card>
        <!-- 产品系列图 -->
        <el-card
          class="box-card"
          shadow="never"
          v-if="section.title == 'PRODUCTS'"
        >
          <div slot="header" class="clearfix">
            <span>产品系列图 [拖动排序] </span>
            <div style="float: right; padding: 3px 0">
              <el-button size="small" @click="onRefresh">刷新</el-button>
              <el-button type="primary" size="small" @click="onSave"
                >保存</el-button
              >
            </div>
          </div>
          <DraggbleSort
            syncSortKey
            v-model="section.images"
            class="banner-list-wrap"
          >
            <template v-slot:default="slotProps">
              <el-card class="box-card">
                <ProductImgUpload
                  class="banner-item"
                  tips="选择图片"
                  v-model="slotProps.item.url"
                  @remove="removeBanner(section, slotProps.index)"
                />
                <br />
                <el-input
                  type="input"
                  placeholder="请输入"
                  v-model="slotProps.item.title"
                ></el-input>
                <br />
                <!-- <el-input
                  type="input"
                  disabled
                  placeholder="请输入跳转地址"
                  v-model="slotProps.item.icon"
                ></el-input> -->
              </el-card>
            </template>
          </DraggbleSort>
          <div class="add-madias-item">
            <el-button-group>
              <el-button
                type="primary"
                size="small"
                icon="el-icon-picture-outline"
                @click="addBanner(section)"
                >添加图片</el-button
              >
            </el-button-group>
          </div>
        </el-card>
        <!-- 团队合照 -->
        <el-card
          class="box-card"
          shadow="never"
          v-if="section.title == 'OUR_TEAM'"
        >
          <div slot="header" class="clearfix">
            <span>团队合照 [拖动排序] </span>
            <div style="float: right; padding: 3px 0">
              <el-button size="small" @click="onRefresh">刷新</el-button>
              <el-button type="primary" size="small" @click="onSave"
                >保存</el-button
              >
            </div>
          </div>
          <DraggbleSort
            syncSortKey
            v-model="section.images"
            class="banner-list-wrap"
          >
            <template v-slot:default="slotProps">
              <ProductImgUpload
                class="banner-item"
                tips="选择图片"
                v-model="slotProps.item.url"
                @remove="removeBanner(section, slotProps.index)"
              />
            </template>
          </DraggbleSort>
          <div class="add-madias-item">
            <el-button-group>
              <el-button
                type="primary"
                size="small"
                icon="el-icon-picture-outline"
                @click="addBanner(section)"
                >添加图片</el-button
              >
            </el-button-group>
          </div>
        </el-card>
      </el-col>
      <el-col :sm="24" :md="12"></el-col>
    </el-row>
  </div>
</template>

<script>
import {
  GetPagesById,
  GetPagesSectionsById,
  PutPagesById,
} from "@/views/pagesManager/api";
import DraggbleSort from "@/components/DraggbleSort";
import ProductImgUpload from "@/components/ProductImgUpload";
import ProductVideoItem from "@/components/ProductVideoItem";
import SingleImgUpload from "@/components/SingleImgUpload";
export default {
  name: "HOME",
  components: {
    DraggbleSort,
    ProductImgUpload,
    ProductVideoItem,
    SingleImgUpload,
  },
  data() {
    return {
      currentPageId: 6,
      sections: [],
      page: {
        name: "",
        parentId: null,
        title: "",
        subTitle: "",
        description: "",
        icon: "",
        sections: [],
        seq: null,
        hidden: null,
      },
    };
  },
  created() {
    this.initSetting();
    // {
    //             "seq": 0,
    //             "hidden": true,
    //             "imageUrl": "",
    //             "typeId": null,
    //             "redirectUrl": "",
    //             "videoCoverUrl": "",
    //             "videoUrl": "",
    //             "title": "banner",
    //             "redirectWxMiniAppPath": "",
    //             "icon": "",
    //             "description": "首页轮播图",
    //             "subTitle": "",
    //             "videos": [],
    //             "images": [
    //                 {
    //                     "url": "https://www.viewglass.cn/_nuxt/banner.6069abaa.jpg",
    //                     "seq": 0,
    //                     "subTitle": "",
    //                     "hidden": false,
    //                     "title": "",
    //                     "description": "",
    //                     "icon": ""
    //                 }
    //             ],
    //             "content": ""
    //         },
    //          {
    //             "seq": 1,
    //             "hidden": true,
    //             "imageUrl": "https://www.viewglass.cn/_nuxt/section1.39bceb11.jpg",
    //             "typeId": null,
    //             "redirectUrl": "",
    //             "videoCoverUrl": "",
    //             "videoUrl": "",
    //             "title": "aboutus",
    //             "redirectWxMiniAppPath": "",
    //             "icon": "",
    //             "description": "首页关于我们",
    //             "subTitle": "",
    //             "videos": [],
    //             "images": [],
    //             "content": "Located in the scenic ceramic hub of Foshan, China, Viewglass has been a trusted name in the glass mosaic industry since 2005. Embracing the evolving trends and the importance of sustainable practices, Viewglass embarked on a transformation journey in 2014, pioneering the manufacturing of recycled glass mosaics in China."
    //         },
    //         {
    //             "seq": 2,
    //             "hidden": true,
    //             "imageUrl": "",
    //             "typeId": null,
    //             "redirectUrl": "",
    //             "videoCoverUrl": "",
    //             "videoUrl": "",
    //             "title": "services",
    //             "redirectWxMiniAppPath": "",
    //             "icon": "",
    //             "description": "首页提供的服务",
    //             "subTitle": "",
    //             "videos": [],
    //             "images": [
    //                 {
    //                     "url": "https://www.viewglass.cn/_nuxt/service1.01d7805a.png",
    //                     "seq": 0,
    //                     "subTitle": "",
    //                     "hidden": false,
    //                     "title": "R & D strength",
    //                     "description": "Professional appearance design, development can realize the whole process from idea to object",
    //                     "icon": ""
    //                 },
    //                  {
    //                     "url": "https://www.viewglass.cn/_nuxt/service2.d5a20b50.png",
    //                     "seq": 1,
    //                     "subTitle": "",
    //                     "hidden": false,
    //                     "title": "Product quality",
    //                     "description": "It has passed ISO9001,ISO14001, ISO45001,Greenleaf, CE certification and many other domestic and foreign certifications",
    //                     "icon": ""
    //                 },
    //                 {
    //                     "url": "https://www.viewglass.cn/_nuxt/service3.99a3e79b.png",
    //                     "seq": 2,
    //                     "subTitle": "",
    //                     "hidden": false,
    //                     "title": "Production ensure",
    //                     "description": "Professional production team, skilled operation, skilled technology, high efficiency and high quality production guarantee",
    //                     "icon": ""
    //                 },
    //                 {
    //                     "url": "https://www.viewglass.cn/_nuxt/service4.2b1b7586.png",
    //                     "seq": 3,
    //                     "subTitle": "",
    //                     "hidden": false,
    //                     "title": "OEM experience",
    //                     "description": "German, Arnerican, Australia,Japanese and Korean brands have many years of OEM experience and high cost performance",
    //                     "icon": ""
    //                 }
    //             ],
    //             "content": ""
    //         },
  },
  methods: {
    initSetting() {
      GetPagesById(this.currentPageId)
        .then((res) => {
          for (const key in res.data) {
            if (Object.hasOwnProperty.call(this.page, key)) {
              this.page[key] = res.data[key];
            }
          }
          return GetPagesSectionsById(this.currentPageId);
        })
        .then((res) => {
          this.sections = res.data;
          console.log("this.sections", this.sections);
        });
    },
    onSave() {
      let sectionData = this.sections.map((item) => {
        if (item.title == "banner") {
          item.seq = 0;
          // 将没有设置类型的默认设置为图片
          if (item.images.length > 0) {
            item.images = item.images.map((item) => {
              if (item.description == "") {
                item.description = "IMAGE";
              }
              return item;
            });
          }
        }
        if (item.title == "aboutus") {
          item.seq = 1;
        }
        if (item.title == "services") {
          item.seq = 2;
        }
        if (item.title == "FACTORY") {
          item.seq = 3;
        }
        if (item.title == "PRODUCTS") {
          item.seq = 4;
        }
        if (item.title == "OUR_TEAM") {
          item.seq = 5;
        }
        return item;
      });
      let _form = {
        ...this.page,
        sections: [...sectionData],
      };
      PutPagesById(this.currentPageId, _form).then(() => {
        this.$message.success("保存成功");
        this.initSetting();
      });
    },
    onRefresh() {
      this.initSetting();
    },
    // 元素图
    addBanner(section, itemType) {
      // _itemType可选值,IMAGE标识图片，VIDEO标识视频
      let _itemType = itemType ? itemType : "";
      let item = {
        title: "",
        subTitle: "",
        description: _itemType,
        icon: "",
        url: "",
        seq: section.images.length + 1,
        hidden: false,
      };
      section.images.push(item);
    },
    removeBanner(section, index) {
      section.images.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrapper {
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow-x: hidden;
  overflow-y: auto;
}
.box-card {
  margin: 10px 0;
}
.banner-list-wrap {
  width: 100%;
  ::v-deep {
    .draggable-item {
      margin: 0 5px;
    }
  }
}
::v-deep {
  .custom-draggable {
    max-width: 100%;
  }
}
.banner-item {
  display: block;
  width: 200px;
  height: 120px;
  margin: 0 auto;
}
.about-us-image {
  width: 100%;
  max-width: 450px;
  height: 260px;
  ::v-deep {
    .img-upload-wrapper {
      height: auto;
    }
    .uploaded-img-preview {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.services-image {
  box-sizing: border-box;
  padding: 2px;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  background-color: hsl(15, 88%, 55%);
  &:hover {
    background-color: hsl(15, 88%, 55%);
  }
}
.add-madias-item {
  text-align: center;
  padding-top: 10px;
}
</style>
