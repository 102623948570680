<template>
  <div class="basic-upload-wrap" v-loading="uploading">
    <el-upload
      accept=".mp4, .avi, .ogg"
      :before-upload="beforeUpload"
      :action="onUploadUrl"
      :headers="headers"
      :on-error="handleError"
      :on-success="handleSuccess"
      :show-file-list="false"
      class="base-uploader"
      :class="[!isEmprty(videoSrc) ? 'mini-uploader' : '']"
      title="选择视频"
    >
      <i class="el-icon-plus img-upload-icon"></i>
      <span class="upload-tip-text" v-if="isEmprty(videoSrc)">点击选择</span>
      <span class="upload-tip-text" v-if="isEmprty(videoSrc)"
        >视频大小限制为{{ toStorage(size * 1024) }}</span
      >
    </el-upload>

    <video
      v-if="!isEmprty(videoSrc)"
      :src="videoSrc"
      class="video-obj"
      controls
    ></video>

    <i
      class="el-icon-delete remove-icon"
      title="点击删除"
      v-if="showDeleteIcon"
      @click="handleRmove"
    ></i>
  </div>
</template>

<script>
import API_CONFIG from "@/request/api.config";
import { getToken } from "@/utils/auth";
import { isEmprty } from "@/utils/validate";
export default {
  name: "ProductVideoItem",
  props: {
    value: {
      type: String,
      default: "",
    },
    showDeleteIcon: {
      type: Boolean,
      default: true,
    },
    // 限制上传图片的文件大小(kb)
    size: {
      type: Number,
      default: 2560,
    },
  },
  data() {
    return {
      uploading: false,
      headers: {
        Authorization: getToken(),
      },
      videoSrc: "",
    };
  },
  computed: {
    onUploadUrl() {
      return API_CONFIG.BASE_V1_API + "/oss/upload/cdn";
    },
  },
  watch: {
    value: {
      handler() {
        this.videoSrc = this.value;
      },
      immediate: true,
    },
  },
  methods: {
    isEmprty,
    beforeUpload(file) {
      let allowType = ["video/mp4", "video/avi", "video/ogg"];
      if (!allowType.includes(file.type)) {
        this.$message.error(`上传的视频格式不合法,格式需要为mp4/avi/ogg`);
        return false;
      }
      this.uploading = true;
      return true;
      // if (this.validImgUpload(file, this.size)) {
      //   this.uploading = true;
      //   return true;
      // } else {
      //   return false;
      // }
    },
    validImgUpload(file, size) {
      size = +size || 10240;
      const isSizeOut = file.size / 1024 > size;
      if (isSizeOut) {
        this.$message.error(
          "上传视频大小不能超过" + this.toStorage(size * 1024)
        );
      }
      return !isSizeOut;
    },
    toStorage(num, digits) {
      digits = digits || 2;
      if (num < 1024) {
        return num + "B";
      }
      num = (num * 1000) / 1024;
      const si = [
        { value: 1e18, symbol: "E" },
        { value: 1e15, symbol: "P" },
        { value: 1e12, symbol: "T" },
        { value: 1e9, symbol: "G" },
        { value: 1e6, symbol: "M" },
        { value: 1e3, symbol: "K" },
      ];
      for (let i = 0; i < si.length; i++) {
        if (num >= si[i].value) {
          return (
            (num / si[i].value)
              .toFixed(digits)
              .replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") +
            si[i].symbol +
            "B"
          );
        }
      }
    },
    handleSuccess(response) {
      this.uploading = false;
      this.$emit("input", response);
    },
    handleError() {
      this.videoSrc = "";
      this.uploading = false;
      this.$emit("input", "");
      this.$message.error("上传失败");
    },
    handleRmove() {
      this.$emit("remove");
    },
  },
};
</script>

<style lang="scss" scoped>
.basic-upload-wrap {
  box-sizing: border-box;
  width: 150px;
  height: 100px;
  border: 1px solid #f1f1f1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  transition: all 0.3s linear;
  background-color: #f1f1f1;
  overflow: hidden;
  .base-uploader {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    .img-upload-icon {
      font-size: 30px;
      color: #333;
      transition: all 0.3s linear;
      &:hover {
        color: #000;
      }
    }
    .upload-tip-text {
      font-size: 12px;
      color: #989898;
    }
    &.mini-uploader {
      width: 25px;
      height: 25px;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 2;
      color: #fff;
      transition: all 0.3s linear;
      border-top-right-radius: 10px;
      background-color: rgba(0, 0, 0, 0.2);
      transform: translateX(-30px) translateY(30px);
      &:hover {
        background-color: rgba(0, 0, 0, 1);
      }
      & .img-upload-icon {
        font-size: 15px;
        color: #fff;
      }
    }
    ::v-deep {
      .el-upload {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
    }
    &:hover {
      background-color: #e9e1e1;
    }
  }
  & .remove-icon {
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-size: 15px;
    text-align: center;
    position: absolute;
    bottom: 0;
    right: 0;
    color: #fff;
    border-top-left-radius: 10px;
    background-color: #c51515;
    z-index: 2;
    cursor: pointer;
    overflow: hidden;
    transition: all 0.3s linear;
    transform: translateX(30px) translateY(30px);
    &:hover {
      background-color: #c51515;
    }
  }
  &:hover {
    .remove-icon,
    .mini-uploader {
      transform: translateX(0px) translateY(0px);
    }
  }
  .video-obj {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
